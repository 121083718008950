#EditLockView {
  padding-bottom: 1em;

  .lock-viewers,
  .viewer-labels,
  .send-code-panel {
    margin-bottom: 1em;
  }

  .edit-lock-button-group {
  }

  .delete-lock-button {
    margin-top: 2em;
  }
}
