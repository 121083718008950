#AccessBar {
  background-color: #00aeef;

  .access-bar-spacer-mobile {
    width: 53.5156px;

    &::before {
      content: none;
    }
  }

  .access-bar-logo-mobile {
    flex-grow: 1;
    justify-content: center;
  }

  .access-bar-logo {
    fill: #ffffff;
    height: 1.2em;
    width: 5em;
  }
}
