#RecoverView {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .conlan-logo {
    fill: #00aeef;
    height: 4em;
    width: 15em;
  }
}
